const login = document.getElementById('login')
if (login !== null) {
    const userPasswordLoginBtn = document.getElementById('userPasswordLogin')

    if (userPasswordLoginBtn !== null) {
        userPasswordLoginBtn.onclick = () => {
            const loginWithAccount = document.getElementById('loginWithAccount')
            if (loginWithAccount !== null) {
                loginWithAccount.classList.remove('hide')
                userPasswordLoginBtn.classList.add('hide')
            }
        }
    }
}
