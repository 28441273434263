﻿
import $ from 'jquery'
import 'jquery-validation'
import 'jquery-validation-unobtrusive'

$(function () {
    $.validator.addMethod('statusrequiresagent',
        function (value, element, params) {
            var status = $(params[0]).val(),
                agent = value;
            if ((agent == "" || agent.length == 0) && status != 0) {
                return false;
            }

            return true;
        });

    $.validator.unobtrusive.adapters.add('statusrequiresagent',
        ['element'],
        function (options) {
            
            var element = $("select#Ticket_Status option:selected").text();
            options.rules['statusrequiresagent'] = [element];
            options.messages['statusrequiresagent'] = options.message;
        });
}($));


$(function () {
    $.validator.addMethod('statusrequiresticketresolution',
        function (value, element, params) {
            var status = $(params[0]).val(),
                resolution = value;
            if ((resolution === "" || resolution.length <= 3) && (status === "3" || status === "4")) {
                return false;
            }

            return true;
        });

    $.validator.unobtrusive.adapters.add('statusrequiresticketresolution',
        ['element'],
        function (options) {
            var element = $(options.form).find('select#Ticket_Status')[0];
            options.rules['statusrequiresticketresolution'] = [element];
            options.messages['statusrequiresticketresolution'] = options.message;
        });
}($));

