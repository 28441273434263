const loadMoreContainer = document.getElementById('loadMoreContainer')

if (loadMoreContainer !== null) {
    const loadMore = document.getElementById('loadMore')
    const loadOlder = document.getElementById('loadOlder')
    const loadNewer = document.getElementById('loadNewer')
    const loadHtmlBefore = document.getElementById('loadHtmlBefore')
    const loadHtmlAfter = document.getElementById('loadHtmlAfter')
    const loadMoreMessage = document.getElementById('loadMoreMessage')

    if (loadMore !== null &&
        loadOlder !== null &&
        loadNewer !== null) {

        const ticketId = loadMore.dataset.ticketId
        const total = loadMore.dataset.total

        loadOlder.onclick = () => {
            fetch(`/Tickets/Comments/${ticketId}/${total}/0/${loadMore.dataset.skipPrev}`)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (loadHtmlBefore !== null) {
                        const div = document.createElement('div')
                        div.innerHTML = data.html
                        loadHtmlBefore.parentNode?.insertBefore(div, loadHtmlBefore)
                    }
                    if (loadMore.dataset.skipPrev) {
                        loadMore.dataset.skipPrev = (parseInt(loadMore.dataset.skipPrev, 0) + 20).toString()
                    }
                    if (loadMore.dataset.total &&
                        loadMore.dataset.skipPrev &&
                        loadMore.dataset.skipNext &&
                        loadMoreMessage !== null) {
                        let skipPrev = parseInt(loadMore.dataset.skipPrev, 0)
                        let skipNext = parseInt(loadMore.dataset.skipNext, 0)
                        if (skipPrev === 30) skipPrev = 20
                        const remaining = (parseInt(loadMore.dataset.total, 0) - 20) - (skipPrev + skipNext)
                        loadMoreMessage.innerHTML = `There are ${remaining} hidden comments.`

                        if (remaining <= 0) loadMoreContainer.classList.add('hide')
                    }
                })
                .catch(err => console.error(err))
        }

        loadNewer.onclick = () => {
            fetch(`/Tickets/Comments/${ticketId}/${total}/1/${loadMore.dataset.skipNext}`)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (loadHtmlAfter !== null) {
                        const div = document.createElement('div')
                        div.innerHTML = data.html
                        loadHtmlAfter.parentNode?.insertBefore(div, loadHtmlAfter.nextSibling)
                    }
                    if (loadMore.dataset.skipNext) {
                        loadMore.dataset.skipNext = (parseInt(loadMore.dataset.skipNext, 0) + 20).toString()
                    }
                    if (loadMore.dataset.total &&
                        loadMore.dataset.skipPrev &&
                        loadMore.dataset.skipNext &&
                        loadMoreMessage !== null) {
                        let skipPrev = parseInt(loadMore.dataset.skipPrev, 0)
                        let skipNext = parseInt(loadMore.dataset.skipNext, 0)
                        if (skipNext === 30) skipNext = 20
                        const remaining = (parseInt(loadMore.dataset.total, 0) - 20) - (skipPrev + skipNext)
                        loadMoreMessage.innerHTML = `There are ${remaining} hidden comments.`

                        if (remaining <= 0) loadMoreContainer.classList.add('hide')
                    }
                })
                .catch(err => console.error(err))
        }
    }
}
