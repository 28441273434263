import '../wwwroot/css/site.scss'

import $ from 'jquery'
import 'jquery-validation'
import 'jquery-validation-unobtrusive'
import 'bootstrap'
import 'materialize-css'
import './TicketValidation'
import './components'

$(function () {

    if ($('#Ticket_Status').val() === '3') {
        $('#resoultionDescription').show()
    } else {
        $('#resoultionDescription').hide()
    }

    $('#Ticket_Status').on('change', () => {
        if ($('#Ticket_Status').val() === '3') {
            $('#resoultionDescription').show()
        } else {
            $('#resoultionDescription').hide()
        }
    })
})

document.addEventListener('DOMContentLoaded', function () {

    const toolTips = document.querySelectorAll('.tooltipped')
    M.Tooltip.init(toolTips)

    const sideNavs = document.querySelectorAll('.sidenav')
    M.Sidenav.init(sideNavs)

    const selects = document.querySelectorAll('select')
    M.FormSelect.init(selects)

    const datePickers = document.querySelectorAll('.datepicker')
    M.Datepicker.init(datePickers)

    $.validator.setDefaults({ ignore: ':disabled' })

    const showAdminEvents = document.getElementById('showAdminEvents')
    if (showAdminEvents !== null) {
        showAdminEvents.addEventListener('change', (event: Event) => {
            const target = event.target as HTMLInputElement | null
            if (target !== null) {
                if (target.checked) {
                    const adminHide = document.querySelectorAll('.admin-hide')
                    adminHide.forEach(el => {
                        el.classList.remove('admin-hide')
                        el.classList.add('admin-show')
                    })
                } else {
                    const adminHide = document.querySelectorAll('.admin-show')
                    adminHide.forEach(el => {
                        el.classList.add('admin-hide')
                        el.classList.remove('admin-show')
                    })
                }
            }
        })
    }
})
