
const ticketForm = document.getElementById('frmTicket')
if (ticketForm !== null) {

    const addAttachment = document.getElementById('AddAttachment')
    const fileContainer = document.getElementById('FileContainer')
    const removeAttachment = document.getElementById('RemoveAttachment')
    const file = document.getElementById('File') as HTMLInputElement | null

    if (addAttachment !== null &&
        fileContainer !== null &&
        removeAttachment !== null &&
        file !== null) {
        addAttachment.onclick = () => {
            addAttachment.classList.add('hide')
            fileContainer.classList.remove('hide')
            removeAttachment.classList.remove('hide')
        }

        removeAttachment.onclick = () => {
            addAttachment.classList.remove('hide')
            fileContainer.classList.add('hide')
            file.classList.remove('valid')
            removeAttachment.classList.add('hide')
            file.value = ''
        }
    }
}
