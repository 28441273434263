import { CommentTypes } from '../enums'

const commentForm = document.getElementById('CommentForm')
if (commentForm !== null) {
    const commentType = document.getElementById('Comment_CommentType') as HTMLInputElement | null
    const commentSubmitButton = document.getElementById('CommentSubmit')
    const resolveSubmitButton = document.getElementById('ResolveSubmit')
    const closeSubmitButton = document.getElementById('CloseSubmit')
    const reopenSubmitButton = document.getElementById('ReopenSubmit')

    if (commentType !== null) {
        if (commentSubmitButton !== null) {
            commentSubmitButton.onclick = () => {
                commentType.value = CommentTypes.Standard.toString()
            }
        }

        if (resolveSubmitButton !== null) {
            resolveSubmitButton.onclick = () => {
                commentType.value = CommentTypes.Resolved.toString()
            }
        }

        if (closeSubmitButton !== null) {
            closeSubmitButton.onclick = () => {
                commentType.value = CommentTypes.Closed.toString()
            }
        }

        if (reopenSubmitButton !== null) {
            reopenSubmitButton.onclick = () => {
                commentType.value = CommentTypes.Reopen.toString()
            }
        }
    }

    commentForm.onsubmit = () => {
        document.querySelectorAll('button').forEach((btn) => btn.setAttribute('disabled', 'disabled'))
    }

    const addAttachment = document.getElementById('AddAttachment')
    const fileContainer = document.getElementById('FileContainer')
    const removeAttachment = document.getElementById('RemoveAttachment')
    const file = document.getElementById('Comment.File') as HTMLInputElement | null

    if (addAttachment !== null &&
        fileContainer !== null &&
        removeAttachment !== null &&
        file !== null) {
        addAttachment.onclick = () => {
            addAttachment.classList.add('hide')
            fileContainer.classList.remove('hide')
            removeAttachment.classList.remove('hide')
        }

        removeAttachment.onclick = () => {
            addAttachment.classList.remove('hide')
            fileContainer.classList.add('hide')
            file.classList.remove('valid')
            removeAttachment.classList.add('hide')
            file.value = ''
        }
    }
}
