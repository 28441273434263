export enum CommentTypes {
    Standard,
    System,
    Email,
    WithAttachment,
    InitialTicketDetail,
    Resolved,
    Closed,
    Reopen
}
